/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import StreamingAvatar, {
    AvatarQuality,
    StreamingEvents,
    TaskType
} from "@heygen/streaming-avatar";
import CryptoJS from 'crypto-js';
import { toast } from "react-toastify";
import { AvatarState, AvatarStatus, ChatMode, Language } from "../types/Types";

export type AvatarComponentRef = {
    stopAvatarState: () => void;
}

// Define types for component props
interface AvatarComponentProps {
    index: number;
    contentLoading: boolean;
    isLastIndex: boolean;
    chatMode: ChatMode;
    message: string;
    loadingMessage: string;
    clearLoadingMessage: () => void;
    updateUserMessage: (message: string) => void;
    greet: boolean;
    setGreet: (greet: boolean) => void;
    avatarState: AvatarState;
    setAvatarState: (state: AvatarState) => void;
    language: Language;

}

export const AvatarComponent = forwardRef<AvatarComponentRef, AvatarComponentProps>(({
    index, contentLoading, isLastIndex, chatMode, message, loadingMessage, clearLoadingMessage, updateUserMessage,
    greet, setGreet, avatarState, setAvatarState, language
}, ref) => {
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [initialized, setInitialized] = useState<boolean>(false);
    const mediaStream = useRef<HTMLVideoElement>(null);
    const avatar = useRef<StreamingAvatar | null>(null);
    const [avatarId] = useState<string>("Wayne_20240711");
    const [voiceId] = useState<string>("867814af4d834e519793267b895aaeb2");
    const [avatarStatus, setAvatarStatus] = useState<AvatarStatus>(AvatarStatus.NOT_INITIALIZED);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isListening, setIsListening] = useState<boolean>(false);
    const [interrupted, setInterrupted] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const avatarContainerRef = useRef<HTMLDivElement>(null);
    

    useImperativeHandle(ref, () => ({
        stopAvatarState: async () => {
            if (avatarState === AvatarState.SPEAKING) {
                interrupt();
            } else {
                if (avatarState === AvatarState.LISTENING) {
                    avatar.current?.closeVoiceChat();
                } else {
                    await avatar.current?.startVoiceChat();
                }
                setIsListening(!isListening);
                setAvatarState(avatarState === AvatarState.LISTENING ? AvatarState.IDLE : AvatarState.LISTENING);
            }
        }
    }));

    // Mutable flag for first talk
    let firstTalk = useRef(true);

    useEffect(() => {
        if (chatMode !== ChatMode.TEXT && avatarStatus === AvatarStatus.INITIALIZED) {
            if (contentLoading) {
                if (isLastIndex) {
                    if (loadingMessage.split(" ").length >= 15) {
                        console.log('loadingMessage', loadingMessage);
                        console.log('started speaking when loading');
                        speak(loadingMessage);
                    }
                } else {
                    console.log('interrupted speaking when loading');
                }
            }
        }
    }, [contentLoading, isLastIndex, avatarStatus, chatMode, loadingMessage]);

    useEffect(() => {
        if (!contentLoading && avatarState !== AvatarState.SPEAKING && loadingMessage.length > 0) {
            console.log('loadingMessage', loadingMessage);
            speak(loadingMessage);
            firstTalk.current = true;
        }
    }, [avatarState, loadingMessage, contentLoading]);

    function decryptToken(encryptedToken: string): string | null {
        try {

            const encryptedData = CryptoJS.enc.Base64.parse(encryptedToken);
            // Separate IV and ciphertext
            const iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, 4), 16);
            const ciphertext = CryptoJS.lib.WordArray.create(encryptedData.words.slice(4));
            const decryptKey = process.env.REACT_APP_MY_DECRYPTION_KEY!.toString();
            // console.log("decryptKey", decryptKey);
            const key = CryptoJS.enc.Utf8.parse(decryptKey);  // Ensure this key matches AES_KEY from backend

            const decrypted = CryptoJS.AES.decrypt(
                { ciphertext: ciphertext } as CryptoJS.lib.CipherParams,
                key,
                { iv: iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC }
            );

            const decryptToken = decrypted.toString(CryptoJS.enc.Utf8);
            // console.log("decryptToken", decryptToken);
            return decryptToken;
        } catch (error) {
            console.error("Decryption failed", error);
            return null;
        }
    }

    function speak(message: string) {
        if (!interrupted && avatarState !== AvatarState.SPEAKING && message.length > 0) {
            mediaStream.current?.play();
            console.log("Avatar started talking");
            avatar.current?.speak({
                text: message,
                task_type: TaskType.REPEAT
            }).catch(() => {
                setAvatarStatus(AvatarStatus.NOT_INITIALIZED);
                init(language);
            });
            clearLoadingMessage();
        }
    }

    async function init(language: Language) {
        setAvatarStatus(AvatarStatus.INITIALIZING);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/getAccessToken`);
            const result = await response.json();
            const token = decryptToken(result.data.token);

            if (!token) {
                throw new Error('Token decryption failed');
            }

            avatar.current = new StreamingAvatar({ token });

            avatar.current.on(StreamingEvents.AVATAR_START_TALKING, () => {
                if (firstTalk.current && !greet) {
                    interrupt();
                    firstTalk.current = false;
                }
                console.log('started talking');
                setIsPlaying(true);
                setAvatarState(AvatarState.SPEAKING);


            });

            avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, () => {
                console.log('stopped talking');
                setIsPlaying(false);
                (chatMode === ChatMode.LIVE_CHAT) ? setAvatarState(AvatarState.LISTENING) : setAvatarState(AvatarState.IDLE);
            });

            avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
                setIsPlaying(false);
                (chatMode === ChatMode.LIVE_CHAT) ? setAvatarState(AvatarState.LISTENING) : setAvatarState(AvatarState.IDLE);
                setAvatarStatus(AvatarStatus.NOT_INITIALIZED);
            });

            avatar.current.on(StreamingEvents.STREAM_READY, (e: any) => {
                setAvatarStatus(AvatarStatus.STREAM_READY);
                setStream(e.detail);
            });

            avatar.current.on(StreamingEvents.USER_TALKING_MESSAGE, (e) => {
                console.log("user message", e);
                updateUserMessage(e.detail.message);
                setInterrupted(false);
                mediaStream.current?.pause();
            });

            setInitialized(true);

            await avatar.current?.createStartAvatar({
                quality: AvatarQuality.Low,
                avatarName: avatarId,
                voice: {
                    voiceId: voiceId
                },
                language: language.isoCode
            });

            // if (res) setData(res);
            await avatar.current?.startVoiceChat();
        } catch (error) {
            toast.error("Something went wrong. Please try again later.");
            console.error('Error starting avatar session:', error);
        }
    }

    useEffect(() => {
        if (stream && mediaStream.current) {
            mediaStream.current.srcObject = stream;
            console.log('stream', mediaStream.current?.srcObject);
            mediaStream.current.onloadedmetadata = () => {
                if (mediaStream.current) {
                    setAvatarStatus(AvatarStatus.INITIALIZED);
                    mediaStream.current.play().then(async () => {
                        if (greet) {
                            speak(language.greetings[Math.floor(Math.random() * language.greetings.length)]);
                            setGreet(false);
                        }
                        if (chatMode === ChatMode.LIVE_CHAT) {
                            await avatar.current?.startListening();
                            setIsListening(true);
                            setAvatarState(AvatarState.LISTENING);
                        } 
                    }).catch((e) => {
                        console.log('Stream play error', e);
                    });
                }
            };
        }
    }, [mediaStream, stream]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                console.log('isVisible', entry.isIntersecting);
                setIsVisible(entry.isIntersecting);
                // firstTalk.current = entry.isIntersecting;
            },
            {
                threshold: 1.0
            }
        );

        if (chatMode !== ChatMode.TEXT) {
            init(language);
            avatarContainerRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });

            if (avatarContainerRef.current) {
                observer.observe(avatarContainerRef.current);
            }
        } else {
            stop();
            if (avatarContainerRef.current) {
                observer.unobserve(avatarContainerRef.current);
            }
        }

        return () => {
            console.log('stopped streaming');
            stop();
        }
    }, [chatMode, language]);

    async function stop() {
        avatar.current?.closeVoiceChat();
        if (avatarStatus === AvatarStatus.INITIALIZED) { await avatar.current?.stopAvatar(); }
    }

    async function interrupt() {
        try {
            if (avatarState === AvatarState.SPEAKING) {
                await avatar.current?.interrupt();
                if (contentLoading) setInterrupted(true);
            }
        } catch (e) {
            mediaStream.current?.pause();
        }
    }

    /* const handlePlayPause = () => {
        if (isPlaying) {
            interrupt();
        } else {
            speak(message);
        }
    }; */

    return (
        <div
            key={index}
            ref={avatarContainerRef}
            id={'avatar-container'}
            className={'flex flex-col h-[80%] relative lg:p-4'}
        >
            {(avatarStatus === AvatarStatus.INITIALIZING) ? (
                <div className={'animate-pulse h-[80%] w-full absolute top-0 start:0 lg:start-4 p-2'}>
                    <div className="w-full h-full bg-slate-200 rounded-xl"></div>
                </div>
            ) : <div className="relative flex-grow">
                <video
                    ref={mediaStream}
                    autoPlay
                    playsInline
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "0.75rem"
                    }}
                    onAnimationStart={() => {
                        setAvatarStatus(AvatarStatus.INITIALIZED);
                    }}
                >
                    <track kind="captions" />
                </video>
                {/* <div className="absolute bottom-2 right-2 text-white">
                    {(avatarState === AvatarState.SPEAKING && !mediaStream.current?.paused) ? "Speaking..." : (contentLoading ? "Processing..." : avatarState === AvatarState.LISTENING && 'Listening...')}
                </div> */}
            </div>}
            

            {/* <div
                className={'hidden hover:flex absolute top-0 start:0 lg:start-4'}
            >
                {initialized && isPlaying && (
                    <img
                        className={"m-auto"}
                        alt="pause"
                        src={pause}
                        onClick={handlePlayPause}
                        width={80}
                        height={80}
                    />
                )}
            </div> */}

            {/* {((avatarStatus === AvatarStatus.STREAM_READY || avatarStatus === AvatarStatus.INITIALIZED) && chatMode === ChatMode.LIVE_CHAT) && <div className={'flex items-center justify-end gap-2 mt-3'}>
                <button onClick={async () => {
                    if (avatarState === AvatarState.SPEAKING) {
                        interrupt();
                    } else {
                        if (avatarState === AvatarState.LISTENING) {
                            avatar.current?.closeVoiceChat();
                        } else {
                            await avatar.current?.startVoiceChat();
                        }
                        setIsListening(!isListening);
                        setAvatarState((prevState) => prevState === AvatarState.LISTENING ? AvatarState.IDLE : AvatarState.LISTENING);
                    }

                }} className={'bg-black text-white px-4 py-2 rounded-md'}>
                    {(avatarState === AvatarState.SPEAKING && !mediaStream.current?.paused) ? "Stop Speaking" : (contentLoading ? "Stop Processing" : (avatarState === AvatarState.LISTENING ? 'Stop Listening' : 'Start Listening'))}
                </button>
            </div>} */}
        </div>
    );
});