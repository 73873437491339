import { Autocomplete, AutocompleteItem, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { useState } from "react";
import { Language } from "../types/Types";

export type LanguageSelectionProps = {
    selectedLanguage: Language;
    setSelectedLanguage: (value: Language) => void;
}

export const languages: Language[] = [
    {
        name: "English",
        isoCode: "en",
        greetings: [
            "Hello! How can I assist you today?",
            "Hi there! What can I help you with?",
            "Greetings! Ready to tackle your tasks together?",
            "Hey! Need any assistance?",
            "Good day! How can I make your day easier?"
        ]
    },
    {
        name: "French",
        isoCode: "fr",
        greetings: [
            "Bonjour! Comment puis-je vous aider aujourd'hui?",
            "Salut! Que puis-je faire pour vous?",
            "Coucou! Prêt à accomplir vos tâches ensemble?",
            "Hey! Besoin d'aide?",
            "Bonne journée! Comment puis-je rendre votre journée plus facile?"
        ]
    },
    {
        name: "German",
        isoCode: "de",
        greetings: [
            "Hallo! Wie kann ich Ihnen heute helfen?",
            "Hi! Was kann ich für Sie tun?",
            "Grüße! Bereit, Ihre Aufgaben gemeinsam zu bewältigen?",
            "Hey! Brauchen Sie Hilfe?",
            "Guten Tag! Wie kann ich Ihren Tag einfacher machen?"
        ]
    },
    {
        name: "Italian",
        isoCode: "it",
        greetings: [
            "Ciao! Come posso aiutarti oggi?",
            "Salve! Cosa posso fare per te?",
            "Saluti! Pronto a affrontare i tuoi compiti insieme?",
            "Ehi! Hai bisogno di assistenza?",
            "Buona giornata! Come posso rendere la tua giornata più facile?"
        ]
    },
    {
        name: "Hindi",
        isoCode: "hi",
        greetings: [
            "नमस्ते! मैं आज आपकी कैसे मदद कर सकता हूँ?",
            "नमस्कार! मैं आपकी किस प्रकार सहायता कर सकता हूँ?",
            "सुप्रभात! आज मैं आपकी क्या मदद कर सकता हूँ?",
            "हैलो! क्या आपको किसी सहायता की आवश्यकता है?",
            "नमस्ते! मैं आपकी दिन को आसान कैसे बना सकता हूँ?"
        ]
    },
    {
        name: "Tamil",
        isoCode: "ta",
        greetings: [
            "வணக்கம்! இன்று உங்களுக்கு எப்படி உதவலாம்?",
            "ஹலோ! உங்களுக்கு என்ன உதவி தேவை?",
            "அன்பு நல்வாழ்த்துக்கள்! உங்கள் பணிகளை ஒன்றாகச் செய்ய தயாரா?",
            "ஹாய்! உங்களுக்கு உதவி தேவை?",
            "நல்ல நாள்! உங்கள் நாளை எவ்வாறு எளிதாக்கலாம்?"
        ]
    }
];

export const LanguageSelection: React.FC<LanguageSelectionProps> =
    ({
        selectedLanguage, setSelectedLanguage
    }) => {
        const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
        const [language, setLanguage] = useState(selectedLanguage);
        return (
            <>
                <button className="flex w-fit gap-2 items-center rounded-full border border-gray-300 px-3 py-1 bg-white"
                    onClick={onOpen}>
                    {selectedLanguage.name}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                        className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </button>
                <Modal isOpen={isOpen} onClose={onOpenChange}>
                    <ModalContent>
                        <ModalHeader>Choose your preferred language</ModalHeader>
                        <ModalBody>
                            <Autocomplete aria-label="Select Language" placeholder="Select Language"
                            inputProps={{
                                classNames:{ input: "border-none outline-none focus:ring-0" }
                            }}
                            selectedKey={language.isoCode}
                            onSelectionChange={(key) => {
                                const selectedLanguage = languages.find((language) => language.isoCode === key);
                                if (selectedLanguage) {
                                    setLanguage(selectedLanguage);
                                }
                            }}>
                                {
                                    languages.map((language) => (
                                        <AutocompleteItem key={language.isoCode} value={language.isoCode}>
                                            {language.name}
                                        </AutocompleteItem>
                                    ))
                                }
                            </Autocomplete>
                            
                        </ModalBody>
                        <ModalFooter>
                            <button className="px-3 py-1 rounded-md border text-base" onClick={onClose}>Cancel</button>
                            <button className="px-3 py-1 bg-black rounded-md text-white text-base" onClick={() => {
                                setSelectedLanguage(language);
                                onClose();
                            }}>Confirm</button>
                            
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>

        );
    }