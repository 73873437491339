export interface Chat {
  id: number;
  createdAt: string;
  messages: Message[];
  threadId?: string;
  tokenId?: string;
}

export interface Message {
  byUser: boolean;
  message: string;
  topSources: Source[];
  status?: string;
  videoMode?: boolean;
}

export interface Source {
  img: string;
  link: string;
  title: string;
  date: string;
}

export interface ChatData {
  selected: number;
  chats: Chat[];
}

export interface RequestBody {
  query: string;
  deviceId: string;
  tokenId: string;
  threadId?: string;
}

export type Language = {
  name: string;
  isoCode: string;
  greetings: string[];
};

// Enum for Avatar Status
export enum AvatarStatus {
  NOT_INITIALIZED,
  INITIALIZING,
  INITIALIZED,
  STREAM_READY,
}

export enum AvatarState {
  IDLE,
  LISTENING,
  PROCESSING,
  SPEAKING,
}

export enum ChatMode {
  TEXT,
  VIDEO,
  LIVE_CHAT,
  PITCH_FABRICE,
}
